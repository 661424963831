
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { AppShell, SelectPlanBanner, CalendarList, WhatsAppWidget, } from "ui_components";
import { useCalendars, useUser } from "hooks";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import useTranslateFunction from "@hooks/useTranslateFunction";
import { usePrompt } from "@hooks/usePrompt";
import mobileApp, { useMobileAppHelper } from "lib/mobileApp";
import { Dialog, DialogContent, DialogTitle, } from "ui_components/shadcn/ui/dialog";
import { AskForPushNotificationsPermission } from "ui_components/AskForPushNotificationsPermission";
import { AddCalendarList } from "ui_components/AddCalendarList";
export default function AppHome() {
    const [isRedirecting, setIsRedirecting] = useState(true);
    const { user, isLoading } = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslateFunction();
    const { openPrompt } = usePrompt();
    const { isMobileApp } = useMobileAppHelper();
    const router = useRouter();
    const { loading, calendars, loading: loadingCalendars, numberOfEventsThisMonthPerCalendar, } = useCalendars();
    function closeModal() {
        setIsModalOpen(false);
    }
    useEffect(() => {
        if (!user || loading || loadingCalendars || isLoading) {
            return;
        }
        if (user &&
            user.appMode === "events" &&
            user?.finishedOnBoarding === true) {
            router.push("/app/events");
        }
        else if (user &&
            user?.finishedOnBoarding === false &&
            // if user has at least a calendar, but didn't finish onboarding
            // we don't take him through onboarding
            calendars?.length === 0) {
            router.push("/app/onboard-new/1-welcome");
        }
        else {
            setIsRedirecting(false);
        }
    }, [user]);
    function openAddCalendarModal() {
        setIsModalOpen(true);
    }
    if (isRedirecting) {
        return null;
    }
    return (<AppShell title="" banner={<SelectPlanBanner></SelectPlanBanner>} loading={isRedirecting}>
      <CalendarList calendars={calendars} numberOfEventsThisMonthPerCalendar={numberOfEventsThisMonthPerCalendar} onAddCalendar={openAddCalendarModal}></CalendarList>
      <WhatsAppWidget buttonText={t("appIndex_¿Necesitas ayuda?")} message={t("appIndex_Hola, necesito ayuda con Confirmafy.")}></WhatsAppWidget>
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-2xl" aria-describedby={undefined}>
          <DialogTitle>
            {t("appIndex_Crea el calendario donde guardarás las citas")}
          </DialogTitle>
          <AddCalendarList closeModal={closeModal}></AddCalendarList>
        </DialogContent>
      </Dialog>
      <AskForPushNotificationsPermission></AskForPushNotificationsPermission>
    </AppShell>);
}

    async function __Next_Translate__getStaticProps__195fcb4cd91__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/app/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fcb4cd91__ as getStaticProps }
  